<template>
    <tr class="setup-changes-brand__retrosend" >
        <td>
            <deq-date :date="log.date" format="ddd D MMM, YYYY HH:mm"/>
            <deq-user :user="log.user"/>
        </td>
        <td>
            <span class="mini-log__detail">
                Sent
                <deq-number :number="log.metadata.mentionsSent"/> {{formatPlural(log.metadata.mentionsSent, "mention")}}
                <span v-if="log.metadata.brand"> for {{log.metadata.brand.name}}</span>.
            </span>
            {{/* log.description */}}
            <br/>
            <markdown-text v-if="user.admin" :text="log.comment"/>
        </td>
    </tr>
</template>

<script>
import DeqNumber from "@/components/formatters/DeqNumber";
import DeqDate from "@/components/formatters/DeqDate";
import MarkdownText from "@/components/MarkdownText";
import {mapState} from "vuex";
import {formatPlural} from "@/app/utils/Format";
import DeqUser from "@/components/formatters/DeqUser";

export default {
    name: "RetrosendLog",
    components: {DeqUser, DeqNumber, DeqDate, MarkdownText},
    props: {
        log: Object
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        formatPlural
    }
}
</script>